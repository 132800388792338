import React from 'react';

const SalesforceCommunityCloud = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5562 14.2254C18.8674 14.2254 16.9204 10.2744 16.9204 7.7179C16.9204 6.46665 17.4162 5.26666 18.2986 4.38189C19.181 3.49713 20.3778 3 21.6258 3C22.8737 3 24.0705 3.49713 24.9529 4.38189C25.8353 5.26666 26.331 6.46665 26.331 7.7179C26.2614 10.2744 24.2449 14.2254 21.5562 14.2254ZM21.5562 4.16203C20.6175 4.16812 19.719 4.54477 19.0552 5.21029C18.3915 5.87582 18.016 6.77672 18.0099 7.7179C18.0099 9.73983 19.6555 13.0633 21.5562 13.0633C23.4568 13.0633 25.1026 9.73983 25.1026 7.7179C25.0965 6.77672 24.7208 5.87582 24.0571 5.21029C23.3933 4.54477 22.4948 4.16812 21.5562 4.16203Z"
      fill="#F24892"
    />
    <path
      d="M30.0398 21.362H12.7716C12.6179 21.362 12.4704 21.3007 12.3618 21.1917C12.2531 21.0828 12.1921 20.935 12.1921 20.781V16.9462C12.1973 16.8185 12.2426 16.6958 12.3217 16.5956C12.4009 16.4953 12.5097 16.4228 12.6325 16.3885L19.3775 14.5294V13.042C19.3775 12.8879 19.4386 12.7401 19.5473 12.6312C19.6559 12.5222 19.8033 12.4609 19.957 12.4609C20.1106 12.4609 20.258 12.5222 20.3667 12.6312C20.4753 12.7401 20.5364 12.8879 20.5364 13.042V14.9708C20.5364 15.0965 20.4958 15.2189 20.4206 15.3195C20.3453 15.4201 20.2396 15.4934 20.1192 15.5286L13.3511 17.3879V20.1999H29.5762V17.3647L23.0862 15.4588C22.9658 15.4236 22.8601 15.3503 22.7848 15.2497C22.7096 15.1491 22.6688 15.0267 22.6688 14.901V13.0885C22.6688 12.9344 22.7299 12.7864 22.8386 12.6774C22.9473 12.5684 23.0946 12.5075 23.2483 12.5075C23.402 12.5075 23.5495 12.5684 23.6581 12.6774C23.7668 12.7864 23.8278 12.9344 23.8278 13.0885V14.4596L30.341 16.3652C30.4613 16.4004 30.5671 16.474 30.6423 16.5746C30.7175 16.6751 30.7583 16.7972 30.7583 16.9229V20.8042C30.7574 20.8919 30.7367 20.9782 30.6978 21.0567C30.6589 21.1352 30.6026 21.2036 30.5335 21.2573C30.4644 21.3109 30.3841 21.3484 30.2985 21.3665C30.213 21.3846 30.1246 21.383 30.0398 21.362Z"
      fill="#F24892"
    />
    <path
      d="M33.0066 37.8618C30.3179 37.8618 28.3708 33.9111 28.3708 31.3546C28.3708 30.1034 28.8667 28.9034 29.7491 28.0186C30.6315 27.1338 31.8283 26.6367 33.0762 26.6367C34.3241 26.6367 35.5209 27.1338 36.4033 28.0186C37.2857 28.9034 37.7814 30.1034 37.7814 31.3546C37.7119 33.9111 35.6721 37.8618 33.0066 37.8618ZM33.0066 27.7987C32.0639 27.7987 31.1594 28.1726 30.4907 28.8388C29.8219 29.505 29.4432 30.4094 29.4371 31.3546C29.4371 33.3765 31.1059 36.6998 33.0066 36.6998C34.9073 36.6998 36.553 33.3765 36.553 31.3546C36.553 30.4094 36.1801 29.5025 35.5157 28.832C34.8513 28.1614 33.9493 27.7816 33.0066 27.7755V27.7987Z"
      fill="#FCFCFD"
    />
    <path
      d="M41.4902 44.9951H24.222C24.0684 44.9951 23.9209 44.9338 23.8122 44.8248C23.7035 44.7159 23.6426 44.5681 23.6426 44.4141V40.5793C23.6426 40.4536 23.6832 40.3312 23.7584 40.2306C23.8337 40.1301 23.9394 40.0568 24.0598 40.0216L30.8279 38.1622V36.6748C30.8279 36.5207 30.889 36.3729 30.9977 36.264C31.1064 36.155 31.2537 36.0938 31.4074 36.0938C31.5611 36.0938 31.7084 36.155 31.8171 36.264C31.9258 36.3729 31.9869 36.5207 31.9869 36.6748V38.6039C31.9817 38.7316 31.9364 38.8543 31.8573 38.9546C31.7781 39.0548 31.6693 39.1273 31.5465 39.1617L24.8015 41.0207V43.833H40.8412V40.9975L34.3279 39.0919C34.2094 39.0535 34.1057 38.9793 34.031 38.8794C33.9563 38.7795 33.9143 38.6589 33.9107 38.5341V36.7213C33.9106 36.5711 33.9684 36.4268 34.0722 36.3184C34.1759 36.2101 34.3174 36.1463 34.467 36.1403C34.6229 36.1402 34.7728 36.2007 34.8852 36.3091C34.9976 36.4174 35.0637 36.5651 35.0697 36.7213V38.0924L41.5829 39.9983C41.7014 40.0367 41.8051 40.1109 41.8798 40.2108C41.9545 40.3107 41.9965 40.4312 42.0001 40.556V44.4141C42.0011 44.5568 41.9497 44.6947 41.8556 44.8019C41.7616 44.909 41.6316 44.9779 41.4902 44.9951Z"
      fill="#FCFCFD"
    />
    <path
      d="M9.34082 37.8618C6.67527 37.8618 4.70508 33.9111 4.70508 31.3546C4.70508 30.1034 5.20088 28.9034 6.0833 28.0186C6.96571 27.1338 8.16251 26.6367 9.41043 26.6367C10.6583 26.6367 11.8551 27.1338 12.7376 28.0186C13.62 28.9034 14.1156 30.1034 14.1156 31.3546C14.0461 33.9111 12.0296 37.8618 9.34082 37.8618ZM9.34082 27.7987C8.40215 27.8048 7.50364 28.1815 6.83989 28.847C6.17613 29.5125 5.80063 30.4134 5.79455 31.3546C5.79455 33.3765 7.46335 36.6998 9.34082 36.6998C11.2183 36.6998 12.9104 33.3765 12.9104 31.3546C12.9104 30.4054 12.5344 29.4949 11.865 28.8237C11.1955 28.1525 10.2875 27.7755 9.34082 27.7755V27.7987Z"
      fill="#FCFCFD"
    />
    <path
      d="M17.8476 44.9951H0.579468C0.425784 44.9951 0.278437 44.9338 0.169766 44.8248C0.0610947 44.7159 0 44.5681 0 44.4141V40.5793C0 40.4536 0.0406375 40.3312 0.115865 40.2306C0.191093 40.1301 0.296836 40.0568 0.4172 40.0216L7.18535 38.1622V36.6748C7.18222 36.5996 7.1943 36.5247 7.22086 36.4543C7.24741 36.384 7.28786 36.3197 7.33984 36.2654C7.39181 36.2111 7.45428 36.1678 7.52332 36.1383C7.59237 36.1088 7.66657 36.0937 7.74161 36.0938C7.8953 36.0938 8.04279 36.155 8.15146 36.264C8.26013 36.3729 8.32108 36.5207 8.32108 36.6748V38.6039C8.31596 38.7316 8.27063 38.8543 8.19149 38.9546C8.11236 39.0548 8.00363 39.1274 7.88082 39.1617L1.13573 41.0207V43.833H17.3608V40.9975L10.8476 39.0919C10.7273 39.0567 10.6215 38.9834 10.5463 38.8828C10.4711 38.7822 10.4304 38.6598 10.4304 38.5341V36.7213C10.4237 36.6453 10.4333 36.5689 10.4586 36.4969C10.4838 36.4249 10.5242 36.359 10.5768 36.304C10.6295 36.2489 10.6935 36.206 10.7642 36.1777C10.8348 36.1495 10.9107 36.1367 10.9867 36.1403C11.1404 36.1403 11.2877 36.2015 11.3964 36.3105C11.5051 36.4195 11.5662 36.5672 11.5662 36.7213V38.0924L18.0794 39.9983C18.1961 40.0395 18.2981 40.1143 18.3724 40.2136C18.4466 40.3129 18.4898 40.4321 18.4966 40.556V44.4141C18.4972 44.4966 18.4803 44.5783 18.4469 44.6538C18.4136 44.7292 18.3645 44.7965 18.303 44.8515C18.2416 44.9065 18.1692 44.9476 18.0907 44.9724C18.0122 44.9971 17.9294 45.005 17.8476 44.9951Z"
      fill="#FCFCFD"
    />
    <path
      d="M16.0167 32.0976C15.8927 32.0948 15.773 32.0523 15.6749 31.9762C15.5768 31.9 15.5058 31.7945 15.472 31.6749C15.4383 31.5552 15.4438 31.4278 15.4876 31.3114C15.5314 31.1951 15.6112 31.096 15.7154 31.0286L21.116 27.6353C21.1793 27.5934 21.2505 27.5649 21.3251 27.5516C21.3998 27.5383 21.4763 27.5404 21.5501 27.5578C21.6239 27.5752 21.6933 27.6076 21.7542 27.6529C21.8151 27.6982 21.8662 27.7556 21.9042 27.8214C21.9823 27.9513 22.0066 28.1067 21.9719 28.2543C21.9373 28.402 21.8464 28.5301 21.7187 28.6115L16.3412 32.0045C16.246 32.0699 16.132 32.1026 16.0167 32.0976Z"
      fill="#FCFCFD"
    />
    <path
      d="M26.9335 32.2601C26.8354 32.2906 26.7303 32.2906 26.6322 32.2601L21.0924 28.7043C20.967 28.6151 20.8802 28.481 20.8501 28.3298C20.8199 28.1786 20.8486 28.0216 20.9302 27.8909C20.9701 27.827 21.0226 27.7717 21.0844 27.7286C21.1461 27.6856 21.2158 27.6556 21.2895 27.6404C21.3632 27.6252 21.4392 27.6251 21.5129 27.6401C21.5866 27.6552 21.6565 27.6852 21.7183 27.7281L27.258 31.2839C27.3225 31.3262 27.378 31.3808 27.4212 31.4448C27.4643 31.5088 27.4943 31.5807 27.5094 31.6564C27.5245 31.7322 27.5245 31.8102 27.5092 31.8859C27.4938 31.9616 27.4637 32.0334 27.4203 32.0973C27.3582 32.1616 27.2814 32.21 27.1966 32.2383C27.1119 32.2666 27.0217 32.2742 26.9335 32.2601Z"
      fill="#FCFCFD"
    />
    <path
      d="M21.4171 28.7057C21.2634 28.7057 21.1161 28.6444 21.0074 28.5355C20.8987 28.4265 20.8376 28.2788 20.8376 28.1247V23.1279C20.8376 22.9738 20.8987 22.8261 21.0074 22.7171C21.1161 22.6081 21.2634 22.5469 21.4171 22.5469C21.5708 22.5469 21.7181 22.6081 21.8268 22.7171C21.9355 22.8261 21.9966 22.9738 21.9966 23.1279V28.2407C21.9695 28.3739 21.8966 28.4934 21.7907 28.5783C21.6849 28.6632 21.5527 28.7084 21.4171 28.7057Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default SalesforceCommunityCloud;
